import React, { useState } from 'react';
import { TextField, Grid, IconButton, Autocomplete, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { EntityFieldName, IQueryRow, } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { operators } from './FileRuleMock';

const QueryBuilder: React.FC<{ fieldName: EntityFieldName[]; }> = (props) => {
    const { fieldName } = props;

    const [rows, setRows] = useState<IQueryRow[]>([
        { operand1: null, operator: null, operand2: null },
    ]);

    const handleAddRow = () => {
        setRows([...rows, { operand1: null, operator: null, operand2: null }]);
    };

    const handleRemoveRow = (index: number) => {
        setRows(rows.filter((_, i) => i !== index));
    };

    const handleOperandChange = (
        index: number,
        field: 'operand1' | 'operand2',
        value: EntityFieldName | null
    ) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        setRows(newRows);
    };

    const handleOperatorChange = (index: number, value: { name: string; symbol: string }) => {
        const newRows = [...rows];
        newRows[index].operator = value;
        setRows(newRows);
    };

    return (
        <React.Fragment>
            <Typography variant="body2" gutterBottom align='center' padding={1} bgcolor={'#e3f2fd'} mt={1.5} border={'2px solid #1976d2'}>
                <b>FILE LEVEL VALIDATION</b>
            </Typography>
            {rows.map((row, index) => (
                <Grid container spacing={2} alignItems="center" key={index} mt={0.5}>
                    <Grid item xs={4}>
                        <Autocomplete
                            size='small'
                            options={fieldName}
                            getOptionLabel={(option) => option.fieldDisplayName}
                            value={row.operand1}
                            onChange={(event, newValue) => handleOperandChange(index, 'operand1', newValue)}
                            renderInput={(params) => <TextField {...params} label="Field Name" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            size='small'
                            options={operators}
                            getOptionLabel={(option) => `${option.name} (${option.symbol})`}
                            value={row.operator}
                            onChange={(event, newValue) => handleOperatorChange(index, newValue)}
                            isOptionEqualToValue={(option, value) => option.symbol === value?.symbol}
                            renderInput={(params) => <TextField {...params} label="Operator" />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            size='small'
                            options={fieldName}
                            getOptionLabel={(option) => option.fieldDisplayName}
                            value={row.operand2}
                            onChange={(event, newValue) => handleOperandChange(index, 'operand2', newValue)}
                            renderInput={(params) => <TextField {...params} label="Field Name" />}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {index === rows.length - 1 && (
                            <IconButton onClick={handleAddRow}>
                                <AddCircleOutlineOutlinedIcon color='primary' />
                            </IconButton>
                        )}
                        <IconButton onClick={() => handleRemoveRow(index)}>
                            <CloseOutlinedIcon color='error' />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
};

export default QueryBuilder;
