import { makeStyles } from '@material-ui/core/styles';
const MapRuleCss = makeStyles((theme) => ({
    listItemStyle: {
        backgroundColor: 'white !important',
        '&:hover': {
            background: "white !important",
        }
    },
    TableCellStyle: {
        background: "#007FFF !important",
        color: "white!important",
        fontSize: "13px !important",
        padding: '5px 15px !important',
        TextAlign: 'left !important'
    },
    TableCellStyle1: {
        fontSize: "13px !important",
        padding: '2px 15px !important',
    },
    TableCellBody: {
        fontSize: "13px !important",
        padding: '4px 15px !important',
        borderBottom: 'none !important'
    },
    tablebody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 210px)',
            minHeight: 'calc( 100vh - 125px)',
        },
    },
}));
export { MapRuleCss };