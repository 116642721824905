import CloseIcon from '@material-ui/icons/Close';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    AppBar, Backdrop, Button, Dialog, DialogContent, Grid, IconButton,
    InputAdornment, Step, StepLabel, Stepper, TextField, Toolbar, Typography
} from '@mui/material';
import axios from 'axios';
import React, { CSSProperties, useEffect, useState } from 'react';
import OtpInput from "react-otp-input";
import { PuffLoader } from 'react-spinners';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';
import { ARMLogo, emailRegex } from '../../constants/Constant';
import { useStyles } from '../NotificationCenter/NotificationCenterCss';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../../auth/authConfig';

const ResetPassword = () => {
    const classes = useStyles();
    const { instance } = useMsal();
    const [open, setOpen] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [validateOTP, setValidateOTP] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [validateOTPError, setValidateOTPError] = useState<string>('');
    const [timeRemaining, setTimeRemaining] = useState<number>(300);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isExternalUser, setIsExternalUser] = useState(false);

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    useEffect(() => {
        const timer =
            timeRemaining > 0 && setInterval(() => setTimeRemaining(timeRemaining - 1), 1000);
        return () => clearInterval(timer);
    }, [timeRemaining]);

    const startTimer = () => {
        setTimeRemaining(300);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
        setEmailAddress('');
        setErrorMessage('');
        setTimeRemaining(300);
        setValidateOTP('');
        setValidateOTPError('');
    }

    const steps = [
        'Reset Your Password',
        'Code Verification',
        'New Credentials',
        'Password Updated',
    ];

    const handleSendEmail = () => {
        setShowProgressBar(true);
        startTimer(); // Reset and start the timer
        setErrorMessage('');
        setValidateOTP('');

        (async () => {
            await axios.get<{ emailExists: number }>("https://webviewuatapi.armsolutions.com/api/UserSignup/CheckAllUserExistReset?emailAddress=" + emailAddress).then(async (exist) => {
                if (exist?.data?.emailExists === 1) {
                    let request = {
                        emailAddress: emailAddress,
                        verificationCode: generateOTP()
                    };

                    const result = await axios.post("https://webviewuatapi.armsolutions.com/api/UserSignup/ResetPasswordSendOTP", request);
                    switch (result?.data) {
                        case "ExternalUser":
                            setIsExternalUser(true);
                            setActiveStep(1);
                            break;
                        case "UserNotFound":
                            setErrorMessage("This email id is not registered with A.R.M Solutions(AD).");
                            setIsExternalUser(false);
                            break;
                        case "InternalUser":
                            setActiveStep(1);
                            setIsExternalUser(false);
                            break;
                        default:
                            break;
                    }
                }
                else {
                    setErrorMessage("This email id is not registered with A.R.M Solutions(Webview).");
                    setIsValidEmail(false);
                }
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    };

    const handleVerifyCode = () => {
        setShowProgressBar(true);
        setValidateOTPError('');
        (async () => {
            let request = {
                verificationCode: validateOTP,
                emailAddress: emailAddress
            }
            const result = await axios.post("https://webviewuatapi.armsolutions.com/api/UserSignup//CheckResetPasswordOTPExist", request);
            switch (result?.data) {
                case "Valid":
                    setValidateOTPError("");
                    setActiveStep(2); // Move to the next step
                    break;
                case "Expired":
                    setValidateOTPError("The OTP you entered is expired. Please try againd");
                    break;
                case "Invalid":
                    setValidateOTPError("The OTP you entered is invalid. Please try again");
                    break;
                case "User not found":
                    setValidateOTPError("User not found. Please check your credentials and try again.");
                    break;
                default:
                    break;
            }
        })().finally(() => {
            setShowProgressBar(false);
        })
    };

    const handleNewPassword = () => {
        setShowProgressBar(true);
        setValidateOTPError('');
        (async () => {
            let request = {
                emailAddress: emailAddress,
                passwordText: confirmPassword,
                isExternalUser: isExternalUser
            }
            await axios.post("https://webviewuatapi.armsolutions.com/api/UserSignup/UpdatePasswordToAD", request).then((response) => {
                setActiveStep(3); // Move to the next step
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    };

    const generateOTP = () => {
        return Math.floor(100000 + Math.random() * 900000).toString();
    };

    // Check if password and confirm password are the same
    const handleValidatePassword = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);

        const error = validatePassword(newPassword);
        if (error) {
            setPasswordError(error);
            setConfirmPasswordError('');
        } else {
            setPasswordError('');
            if (newPassword !== confirmPassword) {
                setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
            } else {
                setConfirmPasswordError('');
            }
        }
    };

    // Check if password and confirm password are the same
    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);

        if (password !== newConfirmPassword) {
            setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
        } else {
            setConfirmPasswordError('');
        }
    };

    const validatePassword = (password) => {
        // Password validation criteria
        const minLength = 8;
        const maxLength = 16;
        const regexUpperCase = /[A-Z]/;
        const regexLowerCase = /[a-z]/;
        const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

        // Check minimum length
        if (password.length < minLength) {
            return "Password must be at least 8 characters long";
        }

        // Check for uppercase letters
        if (!regexUpperCase.test(password)) {
            return "Password must contain at least one uppercase letter";
        }

        // Check for lowercase letters
        if (!regexLowerCase.test(password)) {
            return "Password must contain at least one lowercase letter";
        }

        // Check for special characters
        if (!regexSpecialChar.test(password)) {
            return "Password must contain at least one special character";
        }

        // Check for maximum length
        if (password.length > maxLength) {
            return `Password must not be longer than ${maxLength} characters`;
        }

        return "";
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleValidateEmail = (e) => {
        const email = e.target.value;
        if (!email) {
            setErrorMessage('');
            setIsValidEmail(false);
            return;
        }
        if (email.match(emailRegex)) {
            setErrorMessage('');
            setIsValidEmail(true);
        } else {
            setErrorMessage("Please enter valid email address");
            setIsValidEmail(false);
        }
    };

    const handleChangeInput = (event) => {
        setEmailAddress(event.target.value);
    };

    const handleOTPChange = (otp: string) => {
        setValidateOTPError('');
        setValidateOTP(otp);
    };

    const inputStyle: CSSProperties = {
        border: '1px solid #2377E4',
        borderRadius: '4px',
        width: '50px',
        height: '50px',
        margin: '10px',
        fontSize: '20px',
        textAlign: 'center',
        color: '#495057',
        backgroundColor: '#d3e8ff',
        caretColor: 'blue'
    };

    const getInputStyle = (value: string): CSSProperties => ({
        ...inputStyle,
        color: value ? '#fff' : '#495057',
        backgroundColor: value ? '#2377E4' : '#d3e8ff'
    });

    const focusStyle: CSSProperties = {
        border: '2px solid #2377E4'
    };

    const signout = async () => {
        window.onbeforeunload = null;

        const activeAccount = instance.getActiveAccount();

        // Perform MSAL logout and redirect
        if (activeAccount) {
            await instance.logout({
                account: activeAccount,
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
            });
        } else {
            await instance.logout({
                postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
            });
        }

        // Optional: Clear MSAL cache manually if needed
        localStorage.removeItem('msal.account');
        localStorage.removeItem('msal.idtoken');
        localStorage.removeItem('msal.accessToken');

        localStorage.clear();
        sessionStorage.clear();
    }

    return (
        <React.Fragment>
            <Button variant='text' size='small' onClick={handleOpen}
                sx={{ padding: '0px 72px', color: 'blue', fontSize: '14px', textTransform: 'none', mt: '-8px' }}>
                <b>Reset Password</b>
            </Button>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="relative" className={classes.appBar} >
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="body1" className={classes.title}>
                            <b>RESET PASSWORD</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={showProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Grid container spacing={0} p={"8px 32px"}>
                        <Grid item xs={12}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel color='primary'>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            {activeStep === 0 ?
                                <Grid container spacing={1} mt={7} p={4} width={"35%"} borderRadius={3}>
                                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                        <IconButton color='primary' sx={{ background: '#0072E5', '&:hover': { background: '#0072E5' } }}>
                                            <LockOutlinedIcon htmlColor='#FFFFFF' />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' fontWeight={600} textAlign={'center'} color={'primary'}>
                                            RESET YOUR PASSWORD
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' textAlign={'center'} color={'black'} mt={1}>
                                            Please enter your registered email address and we will send you a 6-digit code.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="outlined-basic" label="Email Address" autoFocus onChange={(e) => { handleChangeInput(e); handleValidateEmail(e); }}
                                            size='small' fullWidth variant="outlined" sx={{ mt: 1 }}
                                            error={!!errorMessage}
                                            helperText={errorMessage ?
                                                (<Typography variant="body2" fontSize={'12px'} color="error">
                                                    <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
                                                </Typography>)
                                                : null
                                            }

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" fullWidth sx={{ mt: 1 }} onClick={handleSendEmail}
                                            disabled={!isValidEmail || !!errorMessage}>GET CODE</Button>
                                    </Grid>
                                </Grid>
                                : activeStep === 1 ?
                                    <Grid container spacing={1} mt={7} p={4} width={"35%"} borderRadius={3}>
                                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                            <IconButton color='primary' sx={{ background: '#0072E5', '&:hover': { background: '#0072E5' } }}>
                                                <ConfirmationNumberOutlinedIcon htmlColor='#FFFFFF' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h6' fontWeight={600} textAlign={'center'} color={'primary'}>
                                                CODE VERIFICATION
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body2' textAlign={'center'} color={'black'} mt={1}>
                                                We sent confirmation code to <b>{emailAddress}</b>. Be sure to check you Junk or Spam folder.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                            <OtpInput
                                                value={validateOTP}
                                                onChange={handleOTPChange}
                                                numInputs={6}
                                                shouldAutoFocus
                                                isInputNum={true}
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props, index) => (
                                                    <input
                                                        {...props}
                                                        style={getInputStyle(validateOTP[index] || '')}
                                                    />
                                                )}
                                                inputStyle={inputStyle}
                                                focusStyle={focusStyle}
                                            />
                                            {validateOTPError?.length ?
                                                <Typography variant='body2' textAlign={'center'} color={'red'} mt={1}>
                                                    {validateOTPError}
                                                </Typography>
                                                : null
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" fullWidth sx={{ mt: 1 }} onClick={handleVerifyCode}
                                                disabled={validateOTP.length !== 6 || !!validateOTPError}>VERIFY CODE</Button>
                                        </Grid>
                                        <Grid item xs={12} display={'flex'} justifyContent={'center'} flexDirection={'row'} gap={'5px'}>
                                            <Typography variant='body2' textAlign={'center'} color={'black'} mt={1}>
                                                Didn't receive OTP code?
                                            </Typography>
                                            <Button variant='text' size='small' onClick={handleSendEmail} sx={{ mt: "4px" }} /* disabled={timeRemaining != 0} */>
                                                <b>Resend Code {/* {timeRemaining != 0 ? "in" : null}  */}</b>
                                            </Button>
                                            {timeRemaining > 0 ?
                                                <Typography variant='body2' color={timeRemaining <= 60 ? 'red' : 'black'} mt={1}>
                                                    <b>{formatTime(timeRemaining)}</b>
                                                </Typography>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </Grid>
                                    : activeStep === 2 ?
                                        <Grid container spacing={1} mt={7} p={4} width={"35%"} borderRadius={3}>
                                            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                                <IconButton color='primary' sx={{ background: '#0072E5', '&:hover': { background: '#0072E5' } }}>
                                                    <PasswordOutlinedIcon htmlColor='#FFFFFF' />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h6' fontWeight={600} textAlign={'center'} color={'primary'}>
                                                    NEW CREDENTIALS
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body2' textAlign={'center'} color={'black'} mt={1}>
                                                    Please enter a new password for your account.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField id="outlined-basic"
                                                    required name="password"
                                                    label="Password" variant="outlined"
                                                    type={showPassword ? 'text' : 'password'}
                                                    size="small" fullWidth
                                                    inputProps={{ maxlength: 50 }}
                                                    sx={{ mt: 1 }} autoFocus
                                                    onChange={(e) => { setPassword(e.target.value); handleValidatePassword(e) }}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end" >
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    tabIndex={-1}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                    helperText={passwordError} error={!!passwordError}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField id="outlined-basic"
                                                    required
                                                    name="confirmPassword"
                                                    label="Confirm Password"
                                                    variant="outlined"
                                                    size="small" fullWidth
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    inputProps={{ maxlength: 50 }}
                                                    sx={{ mt: 1 }}
                                                    onChange={(e) => { setConfirmPassword(e.target.value); handleConfirmPasswordChange(e); }}
                                                    InputProps={{
                                                        endAdornment:
                                                            <InputAdornment position="end" >
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowConfirmPassword}
                                                                    onMouseDown={handleMouseDownConfirmPassword}
                                                                    tabIndex={-1}
                                                                    edge="end"
                                                                >
                                                                    {showConfirmPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                    }}
                                                    helperText={confirmPasswordError} error={!!confirmPasswordError}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" fullWidth sx={{ mt: 1 }} onClick={handleNewPassword}
                                                    disabled={!password || !confirmPassword || !!passwordError || !!confirmPasswordError || password !== confirmPassword}>
                                                    UPDATE PASSWORD
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container spacing={1} mt={7} p={4} width={"35%"} borderRadius={3}>
                                            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                                <IconButton color='primary' sx={{ background: '#0072E5', '&:hover': { background: '#0072E5' } }}>
                                                    <DoneAllOutlinedIcon htmlColor='#FFFFFF' />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='h6' fontWeight={600} textAlign={'center'} color={'primary'}>
                                                    PASSWORD UPDATED
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body2' textAlign={'center'} color={'black'} mt={1}>
                                                    Your password has been successfully updated.
                                                    <br />You will be signed out from the current session. Please log in with your new credentials using
                                                    <Typography style={{ color: '#0072E5' }}><b>{emailAddress}</b></Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                                <Button variant="contained" sx={{ mt: 1 }} onClick={() => { signout(); handleClose(); }}>LOGIN</Button>
                                            </Grid>
                                        </Grid>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
};

export default ResetPassword;