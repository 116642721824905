import axios from 'axios';
import React, { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import { MapRuleCss } from '../ManageRuleCss';
import { PuffLoader } from 'react-spinners';
import CloseIcon from '@material-ui/icons/Close';
import { ARMLogo } from '../../../../../constants/Constant';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TooltipSmall from '../../../../GlobalStyles/TooltipSmall';
import { useFetch, usePost } from '../../../../../utils/apiHelper';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import { useStyles } from '../../../../../pages/NotificationCenter/NotificationCenterCss';
import { APIPlacementControlRule } from '../../../../../models/APIPlacementRule/DynamicControlRule'
import { IGetPlacementDefaultRule, IPlacementRuleCategory, ICheckedFields, RuleOperationTypeMap } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import {
    AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel,
    Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography
} from '@mui/material';


const MapPlacementRules = (props) => {
    const classes = useStyles();
    const classes1 = MapRuleCss();
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const { ruleFieldName, entityId, APIKeyId } = props;
    const [accountNumbers, setAccountNumbers] = useState('');
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [ruleValues, setRuleValues] = useState<RuleOperationTypeMap>({});
    const [defaultRules, setDefaultRules] = useState<IGetPlacementDefaultRule[]>([]);
    const [checkedRules, setCheckedRules] = useState<ICheckedFields>({});
    const [isDefaultChecked, setIsDefaultChecked] = useState<ICheckedFields>({});
    const [placementRuleGategory, setPlacementRuleGategory] = useState<IPlacementRuleCategory[]>([]);

    const handleOpen = () => {
        setOpen(true);
        getPlacementRuleCategory();
        getPlacementDefaultRule();
        getFieldDataRules();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCheckboxChange = (ruleOperationTypeEnumId: number) => (event) => {
        setCheckedRules(prevState => ({
            ...prevState,
            [ruleOperationTypeEnumId]: event.target.checked,
        }));
    };

    const handleDefaultCheckboxChange = (ruleOperationTypeEnumId: number) => (event) => {
        const isChecked = event.target.checked;

        setIsDefaultChecked(prevState => ({
            ...prevState,
            [ruleOperationTypeEnumId]: isChecked,
        }));

        setRuleValues(prevState => {
            const defaultRule = defaultRules.find(rule => rule.ruleOperationTypeEnumId === ruleOperationTypeEnumId)?.ruleValue;
            return {
                ...prevState,
                [ruleOperationTypeEnumId]: isChecked ? defaultRule : null,
            };
        });
    };

    const handleSubmit = () => {
        setLoading(true);
        const numbersArray = accountNumbers.split(',').map(num => num.trim()).filter(num => num.length > 0);
        (async () => {
            let request = {
                entityId: entityId,
                accountNumber: numbersArray
            };
            // API Validation
            await usePost<any>('ARMPlacementAccountTest/ValidateArmPlacementAccount', request).then((response) => {
                setResults(response.data);
            }).catch((error) => {
                console.error('Error during API call:', error);
            }).finally(() => {
                setLoading(false);
            })
        })()
    }

    const handleValueChange = (event, ruleId: number) => {
        setRuleValues(prevState => ({
            ...prevState,
            [ruleId]: event.target.value,
        }));
    };

    const getPlacementDefaultRule = () => {
        (async () => {
            await useFetch<IGetPlacementDefaultRule[]>("GlobalSettings/GetDefaultFieldRules?FieldId=" + ruleFieldName?.fieldId).then((result) => {
                setDefaultRules(result.data);
            })
        })()
    }

    const getPlacementRuleCategory = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IPlacementRuleCategory[]>("GlobalSettings/GetPlacementRuleGategory").then((result) => {
                setPlacementRuleGategory(result?.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    };

    const handleSubmitDataFieldRules = () => {
        setShowProgressBar(true);
        (async () => {
            const fieldDataRuleDetail = defaultRules?.filter(rule => checkedRules[rule.ruleOperationTypeEnumId])?.map(rule => ({
                fieldRuleId: 0,
                ruleCategoryId: rule.ruleCategoryId,
                ruleOperationTypeEnumId: rule.ruleOperationTypeEnumId,
                ruleExit: true,
                ruleCondition: true,
                ruleValue: ruleValues[rule.ruleOperationTypeEnumId],
                ruleOutput: '',
                ruleIsActive: true,
            }));

            let request = {
                entityId: entityId,
                fieldId: ruleFieldName?.fieldId,
                fieldDataRuleDetail
            }

            await usePost("GlobalSettings/ManageFieldDataRules", request).then(() => {
                setShowProgressBar(false);
            })
        })()
    }

    const handleTabChange = (tabId: number) => {
        if (tabId !== selectedTab) {
            setSelectedTab(tabId);
        }
    };

    const getFieldDataRules = () => {
        (async () => {
            let request = {
                aPIKeyId: APIKeyId,
                entityId: entityId,
                fieldId: ruleFieldName?.fieldId
            }
            await usePost<IGetPlacementDefaultRule[]>("GlobalSettings/GetPlacementFieldRules", request).then((result) => {
                const extractedRuleValues: RuleOperationTypeMap = {};
                result.data.forEach(rule => {
                    extractedRuleValues[rule.ruleOperationTypeEnumId] = rule.ruleValue;
                });
                setRuleValues(extractedRuleValues);
            })
        })()
    }

    const renderSubControl = (row) => {
        const allComponents = [];
        switch (row?.ruleControlName) {
            case APIPlacementControlRule.TextField:
                {
                    allComponents.push(
                        <TextField size="small" variant="outlined" autoComplete="off" fullWidth
                            id={`${row.fieldRuleId}`}
                            label={`${row?.ruleOperationName}`}
                            value={ruleValues[row.ruleOperationTypeEnumId] || ""}
                            onChange={(e) => handleValueChange(e, row.ruleOperationTypeEnumId)}
                        />
                    )
                    break;
                }
            case APIPlacementControlRule.RadioButton:
                {
                    allComponents.push(
                        <FormControl component="fieldset">
                            <RadioGroup row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={`${row?.ruleOperationName}`}
                                value={ruleValues[row.ruleOperationTypeEnumId]}
                                onChange={(e) => handleValueChange(e, row.ruleOperationTypeEnumId)}
                            >
                                {(row?.defaultControlValue?.split("|"))?.map((result, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={result}
                                        control={<Radio size="small" sx={{ padding: "5px" }} />}
                                        label={result}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    )
                    break;
                }
            case APIPlacementControlRule.Dropdown:
                {
                    let dropDownValue;
                    let optionItems;
                    allComponents.push(
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel htmlFor="outlined-age-native-simple"> {row?.ruleOperationName} </InputLabel>
                            <Select native
                                onChange={(e) => handleValueChange(e, row.ruleOperationTypeEnumId)}
                                label={`${row?.ruleOperationName}`}
                                name={`${row?.ruleOperationName}`}
                                id={`${row?.fieldRuleId}`} >
                                {dropDownValue = (row?.defaultControlValue).split("|")}
                                {optionItems = dropDownValue.map((r) => {
                                    return (
                                        <option value={r}>{r}</option>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    )
                    break;
                }
        }
        return allComponents;
    }

    return (
        <React.Fragment>
            <Button variant='contained' size='medium' fullWidth onClick={handleOpen}>
                Field Rule
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="body1" className={classes.title}>
                            <b>{ruleFieldName?.fieldDisplayName.toUpperCase()} FIELD RULES</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent sx={{ mt: 6, padding: 0 }}>
                    <Container maxWidth="xl">
                        <Grid container spacing={0} mt={1}>
                            <Grid item xs={12} >
                                <Box width={'100%'} borderRadius={2} bgcolor={'white'}>
                                    <TabContext value={value}>
                                        <Box display={'flex'} borderBottom={'1px solid #cc'} >
                                            {placementRuleGategory.map(tab => (
                                                <Button
                                                    key={tab.ruleCategoryId}
                                                    onClick={() => handleTabChange(tab.ruleCategoryId)}
                                                    aria-selected={selectedTab === tab.ruleCategoryId}
                                                    sx={{
                                                        flex: 1,
                                                        m: 1,
                                                        border: selectedTab === tab.ruleCategoryId ? '2px solid #1976d2' : '.5px solid Black',
                                                        backgroundColor: selectedTab === tab.ruleCategoryId ? '#e3f2fd' : 'transparent',
                                                        color: selectedTab === tab.ruleCategoryId ? '#1976d2' : '#000'
                                                    }}
                                                >
                                                    {tab.rulecategoryName}
                                                </Button>
                                            ))}
                                            <Button
                                                key={placementRuleGategory.length}
                                                aria-selected={selectedTab === placementRuleGategory.length}
                                                onClick={() => handleTabChange(placementRuleGategory.length)}
                                                sx={{
                                                    flex: 1,
                                                    m: 1,
                                                    border: selectedTab === placementRuleGategory.length ? '2px solid #1976d2' : '.5px solid Black',
                                                    backgroundColor: selectedTab === placementRuleGategory.length ? '#e3f2fd' : 'transparent',
                                                    color: selectedTab === placementRuleGategory.length ? '#1976d2' : '#000'
                                                }}
                                            >
                                                Validate and Verify
                                            </Button>
                                        </Box>
                                        <Grid sx={{ '&.MuiBox-root css-19kzrtu': { padding: 0 } }} p={'0px !important'}>
                                            {placementRuleGategory?.filter(tab => tab.ruleCategoryId === selectedTab)?.map(tab => (
                                                <React.Fragment key={tab.ruleCategoryId}>
                                                    {defaultRules.filter(rule => rule.ruleCategoryId === tab.ruleCategoryId).length > 0 && (
                                                        <TableContainer className={`scrollbox on-scrollbar ${classes.ruleTable}`} >
                                                            <Table aria-label="dense table" stickyHeader >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell></TableCell>
                                                                        <TableCell>RULE TYPE</TableCell>
                                                                        <TableCell align="left">OPERATION NAME</TableCell>
                                                                        <TableCell align="center">VALUE</TableCell>
                                                                        <TableCell align="center">DEFAULT VALUE</TableCell>
                                                                        <TableCell align="center">DESCRIPTION</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody >
                                                                    {(defaultRules).filter(rule => rule.ruleCategoryId === tab.ruleCategoryId)?.map(row => (
                                                                        <TableRow key={row.fieldRuleId}>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                <Checkbox size='medium' checked={checkedRules[row.ruleOperationTypeEnumId]} onChange={handleCheckboxChange(row.ruleOperationTypeEnumId)} />
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {tab.rulecategoryName.toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {row.ruleOperationName.toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {renderSubControl(row)}
                                                                            </TableCell>
                                                                            <TableCell align="left" sx={{ display: 'flex' }} className={classes1.TableCellBody}>
                                                                                {row.isValueRequired && (
                                                                                    <React.Fragment>
                                                                                        <Checkbox size='medium' checked={isDefaultChecked[row.ruleOperationTypeEnumId] || false} onChange={handleDefaultCheckboxChange(row.ruleOperationTypeEnumId)} />
                                                                                        <TextField variant='outlined' size='small' value={row.ruleValue} fullWidth color='primary' label="Default Value" />
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </TableCell>
                                                                            <TableCell align="center" className={classes1.TableCellBody}>
                                                                                <IconButton>
                                                                                    <TooltipSmall title={row.description}>
                                                                                        <InfoOutlinedIcon color='primary' />
                                                                                    </TooltipSmall>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                            <Box m={0} display={'flex'} justifyContent={'flex-end'} sx={{ padding: '16px' }} >
                                                                <Button variant='contained' size='small' sx={{ mt: 1, width: '250px' }} onClick={handleSubmitDataFieldRules}>Submit</Button>
                                                            </Box>
                                                        </TableContainer>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            {selectedTab !== null && selectedTab == placementRuleGategory.length && (
                                                <TableContainer sx={{
                                                    minHeight: 'calc(100vh - 200px)',
                                                    maxHeight: 'calc(100vh - 200px)',
                                                    border: '1px solid #007FFF',
                                                    borderRadius: '5px',
                                                    padding: 2
                                                }}
                                                    className={`scrollbox on-scrollbar`} key={placementRuleGategory.length}>
                                                    <Typography variant="subtitle1" gutterBottom align='center'>Account Number Validation</Typography>
                                                    <TextField label="Account numbers"
                                                        multiline rows={5}
                                                        variant="outlined" fullWidth
                                                        value={accountNumbers}
                                                        onChange={(e) => setAccountNumbers(e.target.value)}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        sx={{ marginBottom: 2 }}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                        disabled={loading}
                                                        sx={{ marginBottom: 2 }}
                                                    >
                                                        {loading ? <CircularProgress size={24} /> : 'Test'}
                                                    </Button>
                                                    {results.length > 0 && (
                                                        <TableContainer component={Paper}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Account Number</TableCell>
                                                                        <TableCell>Valid</TableCell>
                                                                        <TableCell>Error Message</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {results.map((result, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>{result.item1}</TableCell>
                                                                            <TableCell>{result.item2 ? 'Yes' : 'No'}</TableCell>
                                                                            <TableCell>{result.item3 || 'N/A'}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    )}
                                                </TableContainer>)}
                                        </Grid>
                                    </TabContext>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
};

export default MapPlacementRules;
