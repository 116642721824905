import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TabContext from '@mui/lab/TabContext';
import {
    AppBar, Backdrop, Box, Button, Checkbox, Container, Dialog, DialogContent, Grid, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import QueryBuilder from './QueryBuilder';
import { ARMLogo } from '../../../../../constants/Constant';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import TooltipSmall from '../../../../GlobalStyles/TooltipSmall';
import { useStyles } from '../../../../../pages/NotificationCenter/NotificationCenterCss';
import { useFetch, usePost } from '../../../../../utils/apiHelper';
import { MapRuleCss } from '../ManageRuleCss';
import {
    EntityFieldName, IPlacementFieldsNameResponse, IPlacementRuleCategory
} from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { FileRuleMock } from './FileRuleMock';

const FileEntityRules: React.FC<{ entityId: number }> = (props) => {
    const classes = useStyles();
    const classes1 = MapRuleCss();
    const { entityId } = props;
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [placementFieldName, setPlacementFieldname] = useState<EntityFieldName[]>([]);
    const [placementRuleGategory, setPlacementRuleGategory] = useState<IPlacementRuleCategory[]>([]);

    const handleOpen = () => {
        setOpen(true);
        getPlacementRuleCategory();
        getPlacementFieldName();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTabChange = (tabId: number) => {
        if (tabId !== selectedTab) {
            setSelectedTab(tabId);
        }
    };

    const getPlacementRuleCategory = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IPlacementRuleCategory[]>("GlobalSettings/GetPlacementRuleGategory").then((result) => {
                setPlacementRuleGategory(result?.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    };

    const getPlacementFieldName = () => {
        (async () => {
            await usePost<IPlacementFieldsNameResponse>(`GlobalSettings/GetPlacementFieldsName?EntityId=${entityId}`, entityId).then((result) => {
                setPlacementFieldname(result?.data[0]?.entityFieldNames);
            })
        })();
    };

    return (
        <React.Fragment>
            <Button variant='contained' size='medium' fullWidth onClick={handleOpen}>
                File Rules
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="body1" className={classes.title}>
                            <b>FILE RULES</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent sx={{ mt: 6, padding: 0 }}>
                    <Container maxWidth="xl">
                        <Grid container spacing={0} mt={1}>
                            <Grid item xs={12} >
                                <Box width={'100%'} borderRadius={'10px'} sx={{ background: 'white' }}>
                                    <TabContext value={value}>
                                        <Box display={'flex'} borderBottom={'1px solid #ccc'} p={1}>
                                            {placementRuleGategory.map(tab => (
                                                <Button
                                                    key={tab.ruleCategoryId}
                                                    onClick={() => handleTabChange(tab.ruleCategoryId)}
                                                    aria-selected={selectedTab === tab.ruleCategoryId}

                                                    sx={{
                                                        flex: 1,
                                                        m: 1,
                                                        border: selectedTab === tab.ruleCategoryId ? '2px solid #1976d2' : '.5px solid Black',
                                                        backgroundColor: selectedTab === tab.ruleCategoryId ? '#e3f2fd' : 'transparent',
                                                        color: selectedTab === tab.ruleCategoryId ? '#1976d2' : '#000'
                                                    }}
                                                >
                                                    {tab.rulecategoryName}
                                                </Button>
                                            ))}
                                            <Button
                                                key={placementRuleGategory.length}
                                                aria-selected={selectedTab === placementRuleGategory.length}
                                                onClick={() => handleTabChange(placementRuleGategory.length)}
                                                sx={{
                                                    flex: 1,
                                                    m: 1,
                                                    border: selectedTab === placementRuleGategory.length ? '2px solid #1976d2' : '.5px solid Black',
                                                    backgroundColor: selectedTab === placementRuleGategory.length ? '#e3f2fd' : 'transparent',
                                                    color: selectedTab === placementRuleGategory.length ? '#1976d2' : '#000'
                                                }}
                                            >
                                                Validate and Verify
                                            </Button>
                                        </Box>
                                        <Grid sx={{ padding: '0px !important', '&.MuiBox-root css-19kzrtu': { padding: 0 } }}>
                                            {placementRuleGategory?.filter(tab => tab.ruleCategoryId === 1 && tab?.ruleCategoryId === selectedTab)?.map((tab) => (
                                                <QueryBuilder fieldName={placementFieldName} />
                                            ))}
                                            {placementRuleGategory?.filter(tab => tab.ruleCategoryId === 11 && tab?.ruleCategoryId === selectedTab)?.map(tab => (
                                                <React.Fragment key={tab?.ruleCategoryId}>
                                                    {FileRuleMock.filter(rule => rule?.ruleCategoryId === tab?.ruleCategoryId).length > 0 && (
                                                        <TableContainer className={`scrollbox on-scrollbar ${classes.ruleTable}`}  >
                                                            <Table aria-label="dense table" stickyHeader >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell></TableCell>
                                                                        <TableCell>RULE TYPE</TableCell>
                                                                        <TableCell align="left">OPERATION NAME</TableCell>
                                                                        <TableCell align="center">DESCRIPTION</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody >
                                                                    {(FileRuleMock).filter(rule => rule.ruleCategoryId === tab.ruleCategoryId)?.map(row => (
                                                                        <TableRow key={row.ruleOperationTypeId}>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                <Checkbox size='medium' />
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {tab.rulecategoryName.toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="left" className={classes1.TableCellBody}>
                                                                                {row.ruleOperationName.toUpperCase()}
                                                                            </TableCell>
                                                                            <TableCell align="center" className={classes1.TableCellBody}>
                                                                                <IconButton>
                                                                                    <TooltipSmall title={row.description}>
                                                                                        <InfoOutlinedIcon color='primary' />
                                                                                    </TooltipSmall>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>

                                                            </Table>
                                                            <Box m={0} display={'flex'} justifyContent={'flex-end'} sx={{ padding: '16px' }} >
                                                                <Button variant='contained' size='small' sx={{ mt: 1, width: '250px' }}>Submit</Button>
                                                            </Box>
                                                        </TableContainer>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                            {selectedTab !== null && selectedTab == placementRuleGategory.length && (
                                                <TableContainer sx={{
                                                    minHeight: 'calc(100vh - 200px)',
                                                    maxHeight: 'calc(100vh - 200px)',
                                                    border: '1px solid #007FFF',
                                                    borderRadius: '5px',
                                                    padding: 2
                                                }}
                                                    className={`scrollbox on-scrollbar`} key={placementRuleGategory.length}>
                                                    <Typography variant="h6" gutterBottom align='center'>File Level Validation</Typography>
                                                    <Grid container spacing={1} mt={1}>
                                                        {placementFieldName.map((r) => (
                                                            <Grid item xs={4} >
                                                                <TextField
                                                                    label={r.fieldDisplayName}
                                                                    variant="outlined"
                                                                    size='small'
                                                                    fullWidth
                                                                    InputProps={{
                                                                        readOnly: false,
                                                                    }}
                                                                    sx={{ marginBottom: 2 }}
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                    <Box m={0} display={'flex'} justifyContent={'flex-end'} sx={{ padding: '16px' }} >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{ marginBottom: 2 }}
                                                        >
                                                            TEST
                                                        </Button>
                                                    </Box>
                                                </TableContainer>)}
                                        </Grid>
                                    </TabContext>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    )
}

export default FileEntityRules
