import {
    Backdrop,
    Button, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { DialogTitleHeader } from '../../../../GlobalStyles/DialogStyle';
import { Transition } from '../../../../GlobalStyles/DialogBoxTransition';
import { useStyles } from '../../ManagementCSS';
import TooltipSmall from '../../../../GlobalStyles/TooltipSmall';
import { IManageAPIConfiguration } from '../../../../../models/accesscontrol/PlacementTemplateRule';
import { usePost } from '../../../../../utils/apiHelper';
import apiConfiguration from '../../../../../images/APIPlacementRule/apiConfiguration.svg';
import { PuffLoader } from 'react-spinners';
import * as constant from '../../../../../constants/Constant';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const ManageAPIConfiguration: React.FC<{ APIKeyId: number }> = (props) => {
    const classes = useStyles();
    const { APIKeyId } = props;
    const [open, setOpen] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [toEmailerror, setToEmailError] = useState("");
    const [ccEmailerror, setCCEmailError] = useState("");
    const [isSaved, setIsSaved] = useState(false);

    const initialAPIConfiguration: IManageAPIConfiguration = {
        apiConfigId: 0,
        apiKeyId: APIKeyId,
        toEmailAddress: "",
        ccEmailAddress: "",
        responseUri: "",
        perDayAPILimit: 0,
        responseType: "",
        supportedPhases: ""
    };

    const [localState, setLocalState] = useState<IManageAPIConfiguration>(initialAPIConfiguration);

    const handleOpen = () => {
        handleGetAPIConfiguration();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLocalState(initialAPIConfiguration);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        setProgressBar(true);
        (async () => {
            let request: IManageAPIConfiguration = {
                apiConfigId: localState?.apiConfigId,
                apiKeyId: APIKeyId,  // Ensure this comes from props or state
                toEmailAddress: localState?.toEmailAddress,
                ccEmailAddress: localState?.ccEmailAddress,
                responseUri: localState?.responseUri,
                perDayAPILimit: localState?.perDayAPILimit,
                responseType: localState?.responseType,
                supportedPhases: localState?.supportedPhases
            };
            await usePost("GlobalSettings/ManageAPIConfiguration", request).then((r) => {
                handleGetAPIConfiguration();
                setIsSaved(true);
            }).finally(() => {
                setProgressBar(false);
                handleClose();
                setLocalState(null);
            });
        })();
    }

    const handleGetAPIConfiguration = () => {
        setProgressBar(true);
        (async () => {
            let request = {
                apiKeyId: APIKeyId
            }
            await usePost<IManageAPIConfiguration>("GlobalSettings/GetAPIConfiguration", request).then((response) => {
                setLocalState(response?.data);
            }).finally(() => {
                setProgressBar(false);
            });
        })();
    }

    const validateToEmailAddress = (e) => {
        const email = e.target.value;
        if (constant.emailRegex.test(email)) {
            setToEmailError("");
        } else {
            setToEmailError("Please enter valid Email address");
        }
    };

    const validateCCEmailAddress = (e) => {
        const email = e.target.value;
        if (constant.emailRegex.test(email)) {
            setCCEmailError("");
        } else {
            setCCEmailError("Please enter valid Email address");
        }
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSaved(false);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleOpen}>
                <TooltipSmall title="API Configuration">
                    <img src={apiConfiguration} width="25px" height="25px" alt="api-config" />
                </TooltipSmall>
            </IconButton>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth="md" TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <Backdrop className={classes.backdrop} open={progressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        API Configuration
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>To Email Address</b>
                            </Typography>
                            <TextField
                                name="toEmailAddress"
                                value={localState?.toEmailAddress}
                                onChange={(e) => { handleInputChange(e); validateToEmailAddress(e); }}
                                label="To Email Address"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                fullWidth
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                sx={{ mt: 1 }}
                                required helperText={toEmailerror} error={!!toEmailerror}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>CC Email Address</b>
                            </Typography>
                            <TextField
                                name="ccEmailAddress"
                                value={localState?.ccEmailAddress}
                                onChange={(e) => { handleInputChange(e); validateCCEmailAddress(e); }}
                                label="CC Email Address"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                fullWidth
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                sx={{ mt: 1 }}
                                required helperText={ccEmailerror} error={!!ccEmailerror}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Response URI</b>
                            </Typography>
                            <TextField
                                name="responseUri"
                                value={localState?.responseUri}
                                onChange={(e) => { handleInputChange(e); }}
                                label="Response URI"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                fullWidth
                                variant="outlined"
                                inputProps={{ maxLength: 100 }}
                                sx={{ mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Per Day API Limit</b>
                            </Typography>
                            <TextField
                                name="perDayAPILimit"
                                value={localState?.perDayAPILimit}
                                onChange={(e) => { handleInputChange(e); }}
                                label="Per Day API Limit"
                                autoComplete="off"
                                size="small"
                                className={classes.keyTextFiled}
                                fullWidth
                                type="number"
                                variant="outlined"
                                sx={{ mt: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Supported Phases</b>
                            </Typography>
                            <RadioGroup
                                row
                                name="supportedPhases"
                                value={localState?.supportedPhases}
                                onChange={(e) => { handleInputChange(e); }}
                                sx={{ mt: "5px" }}
                            >
                                <FormControlLabel value="1STP" control={<Radio size='small' />} label="1STP" />
                                <FormControlLabel value="PREC" control={<Radio size='small' />} label="PREC" />
                                <FormControlLabel value="CONT" control={<Radio size='small' />} label="CONT" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='subtitle1' className={classes.headerName}>
                                <b>Response Type</b>
                            </Typography>
                            <RadioGroup
                                row
                                name="responseType"
                                value={localState?.responseType}
                                onChange={(e) => { handleInputChange(e); }}
                                sx={{ mt: "5px" }}
                            >
                                <FormControlLabel value="Immediately" control={<Radio size='small' />} label="Immediately" />
                                <FormControlLabel value="Cumulative" control={<Radio size='small' />} label="Cumulative" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' size='small' color='primary' sx={{ mr: 1 }} onClick={handleSubmit}
                        disabled={!localState.ccEmailAddress || !localState.perDayAPILimit || !localState.responseType ||
                            !localState.responseUri || !localState.supportedPhases || !localState.toEmailAddress || !!toEmailerror.length || !!ccEmailerror.length}>
                        Submit
                    </Button>
                    <Button variant='contained' size='small' color='error' sx={{ mr: 2 }} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                className="snackBarStyle"
                open={isSaved}
                autoHideDuration={4000}
                onClose={closeSnackbar}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    API Configuration Saved Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
};

export default ManageAPIConfiguration;
