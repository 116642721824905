import { Paper, TableCell, TableRow, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Table, TableBody, TableContainer, TableHead, TableSortLabel, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { IAgentDebtor } from '../../../models/AgentInquiry/IAgentDebtor';
import { ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { useGlobalState } from '../../../store/GlobalStore';
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import { StyledTableCell } from '../../GlobalStyles/TableStyledCell';
import { useStyles } from './AgentActivityCss';

const AccountHistory: React.FC<{ debtors: IAgentDebtor, histories: ITransactionHistory[], mapped: boolean }> = (props) => {
    const { histories, mapped } = props;
    const classes = useStyles();
    const { state } = useGlobalState();

    const useProgressStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));

    function Progress(props) {
        const classes = useProgressStyles();
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={30}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={30}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    }

    const headCells = [
        { id: "date", numeric: false, disablePadding: false, label: "DATE", sortable: true },
        { id: "description", numeric: false, disablePadding: false, label: "DESCRIPTION", sortable: false },
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <StyledTableRow>
                    {headCells.map(headCell => (
                        <StyledTableCell
                            key={headCell?.id}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            </TableHead>
        );
    }


    return (
        <React.Fragment >
            <Grid container spacing={0}>
                <Grid item xs={6} p={"5px 0px"}>
                    <Typography variant='body2' fontWeight={600} color={'blue'}>
                        Account History
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={12} mb={2}>
                    <TableContainer component={Paper} sx={{ mt: 1 }}
                        className={`${classes.accountHistoryContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                        <Table aria-label="customized table" size="small" stickyHeader>
                            <EnhancedTableHead classes={classes} />
                            <TableBody>
                                {mapped ?
                                    histories?.map((Histories) => {
                                        return (
                                            <React.Fragment>
                                                {Histories?.activityType !== "0" ? (
                                                    <TableRow>
                                                        <TableCell style={{ padding: "3px" }}>
                                                            <Typography variant='body2' fontSize={'13px'}>
                                                                {moment(Histories.activityDt).format(state.GlobalUtils?.settingValue)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell style={{ padding: "3px" }}>
                                                            <Typography variant='body2' fontSize={'13px'}>
                                                                {Histories.activity?.split('|')[0]}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ) :
                                                    <Typography variant="body2" textAlign={'center'} gutterBottom color={'error'}>
                                                        <b>No Records to Display</b>
                                                    </Typography>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <Progress />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AccountHistory
