import React, { Component } from 'react';
//Navbar Menu
import Faq from './pages/Faq';
import Roll from './pages/Roll';
import Report from './pages/Report';
import Inquiry from './pages/Inquiry';
import Dashboard from './pages/Dashboard';
import Uploadlog from './pages/Uploadlog';
import Placements from './pages/Placements';
import AckReportList from './pages/AckReportList';
import Finish from './pages/Placements/CSVUpload/Finish';
import DocumentUploadlog from './pages/DocumentUploadlog';
import AckBuilder from './components/AckReport/AckBuilder';
import ReportsBuilder from './components/Report/ReportsBuilder';
import Fieldnames from './pages/Placements/ManualEntry/Fieldnames';
import PreviewData from './pages/Placements/ManualEntry/PreviewData';
import CSVFieldNames from './pages/Placements/CSVUpload/CSVFieldNames';
import CSVPreviewData from './pages/Placements/CSVUpload/CSVPreviewData';
import ValidatePlacementRules from './pages/Placements/CSVUpload/ValidatePlacementRules';
import NotificationcenterClient from "./pages/NotificationCenter/NotificationcenterClient";
// Settings Menu
import Settings from './pages/Settings';
import ClientConfirmation from './pages/Settings/ClientConfirmation';
import ClientInformation from './pages/Settings/ClientInformation';
import UserAccessControl from "./pages/Settings/UserAccessControl";
// Admin  Menu
import Admin from "./pages/Admin";
import User from "./pages/Admin/User";
import GAFAQ from "./pages/Admin/GAFAQ";
import AdfControl from './pages/Admin/AdfSettings';
import UpdateHistory from './pages/Admin/UpdateHistory';
import GlobalMessage from "./pages/Admin/GlobalMessage";
import UserloginHistory from './pages/Admin/UserloginHistory';
import Sendnotification from './pages/Admin/Sendnotification';
import FileManager from './components/WebViewFile/FileManager';
import GlobalSettings from "./components/GlobalSettings/GSettings";
import NotificationRollback from './pages/Admin/NotificationRollback';
import Message from './components/GlobalAdmin/SendNotification/Message';
import ClientPlacementOptions from "./pages/Admin/ClientPlacementOptions";
import MassUpload from './components/GlobalAdmin/MassDocUpload/MassUpload';
import AdfPipelineLogs from "./components/GlobalSettings/AdfLogs/AdfPipelineLogs";
import Newfileupload from './components/GlobalAdmin/SendNotification/Newfileupload';
import ManageClientFolderAccess from "./components/FileAccess/ManageClientFolderAccess";
import AzFunctionLogs from "./components/GlobalSettings/Az-FunctionsLogs/AzFunctionLogs";
import GA_EditTemplate from "./components/GlobalAdmin/ClientPlacementOptions/GA_EditTemplate";
import SettlementAuthorization from './components/GlobalAdmin/SendNotification/SettlementAuthorization';
// Agent User
import AgentUsers from "./pages/AgentUser/AgentUsers";
import AgentInquiry from './components/AgentUser/AgentInquiry/AgentInquiry';
import MasterTemplate from './components/AgentUser/MangeActivityTemplate/AgentTemplate';
import ManageTemplate from './components/AgentUser/MangeActivityTemplate/GAManageTemplate';
// User Role Permission
import { Permission, placementPermission, UserRole } from './models/accesscontrol/ClientUserAccessContext';
import { AgentUserRole } from './models/accesscontrol/agentAccessContext';
import PerformanceReportBuilder from './components/PerformanceReport/PerformanceReportBuilder';
import WebviewReports from './components/Report/WebviewReport';
import PerformanceReportView from './components/PerformanceReport/PerformanceReportView';
import ManageReports from './components/AgentUser/ManageReports/ManageReports';
import prospectUsersList from './components/ProspectUser/ProspectUsersList';
import AckAdditionalReport from './components/AddinalChargesAckReport/AckAdditionalReport';
import AdditionalAckBuilder from './components/AddinalChargesAckReport/AdditionalAckBuilder';
import AgentReport from './components/AgentUser/AgentReport/AgentReports';
import GenerateAgentReport from './components/AgentUser/AgentReport/GenerateReport';
import UploadedFiles from './components/PlacementFileManager/UploadedFiles';
import AgentNotificationCenter from './pages/AgentNotification/AgentNotificationCenter';
import AgentDashboard from './pages/AgentDashboard';
import AgentCaseCreatedReport from './components/AgentUser/AgentCaseCreatedReport/AgentCaseCreatedReports';
import GenerateCaseCreatedReport from './components/AgentUser/AgentCaseCreatedReport/GenerateCaseCreatedReport';
import ReportsRequested from './components/Report/ReportsRequested/ReportsRequested';
import TrustStatementReport from './pages/TrustStatementReport/TrustStatementReport';
import SettingsTrustReportShare from './pages/TrustStatementReport/SettingsTrustReportShare/SettingsTrustReportShare';
import ViewKeyManagement from './components/GlobalSettings/KeyManagement/ViewKeyManagement';

export interface IRoute {
  path: string,
  title: string,
  component: React.ComponentType,
  isPrivate?: boolean,
  noNavLink?: boolean,
  UserRole?: UserRole[],
  AgentUserRole?: AgentUserRole[],
  Permission?: Permission[],
  placementPermission?: placementPermission[],
}

const Routes: IRoute[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    component: Dashboard,
    UserRole: [UserRole.ClientAdmin, UserRole.ClientUser],
  },
  {
    path: '/agentdashboard',
    title: 'Dashboard',
    component: AgentDashboard,
    UserRole: [UserRole.AgentUser],
    AgentUserRole: [AgentUserRole.AgentUser]
  },
  {
    path: '/roll',
    title: 'Roll',
    component: Roll,
    UserRole: [UserRole.ClientAdmin, UserRole.ClientUser]
  },
  {
    path: '/inquiry',
    title: 'Inquiry',
    component: Inquiry,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/reportsBuilder/:reportId/:reportName',
    title: 'ReportsBuilder',
    component: ReportsBuilder,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin],
    noNavLink: true
  },
  {
    path: '/report',
    title: 'Reports',
    component: Report,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/placement',
    title: 'Placement',
    component: Placements,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/settings',
    title: 'Settings',
    component: Settings,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/faq',
    title: 'FAQ',
    component: Faq,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin, UserRole.FileAdmin],
  },
  {
    path: '/uploadlog',
    title: 'Upload Log',
    component: Uploadlog,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/documentuploadlog',
    title: 'Document Upload Log',
    component: DocumentUploadlog,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/useraccesscontrol',
    title: 'UserAccessControl',
    component: UserAccessControl,
    noNavLink: true,
    UserRole: [UserRole.ClientAdmin]
  },
  {
    path: '/adfpipelinelogs',
    title: 'AdfPipelineLogs',
    component: AdfPipelineLogs,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/azfunctionlogs',
    title: 'AzFunctionLogs',
    component: AzFunctionLogs,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin]
  },
  {
    path: '/admin',
    title: 'Admin',
    component: Admin,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/globalmessage',
    title: 'GlobalMessage',
    component: GlobalMessage,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/users',
    title: 'User',
    component: User,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/clientplacementoptions',
    title: 'ClientPlacementOptions',
    component: ClientPlacementOptions,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/GA_edittemplate',
    title: 'EditTemplate',
    component: GA_EditTemplate,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/clientconfirmation',
    title: 'Client Confirmation',
    component: ClientConfirmation,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/clientinformation',
    title: 'Client Information',
    component: ClientInformation,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/userloginhistory',
    title: 'UserloginHistory',
    component: UserloginHistory,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/updatehistory',
    title: 'UpdateHistory',
    component: UpdateHistory,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/notificationrollback',
    title: 'NotificationRollback',
    component: NotificationRollback,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/settlementauthorization',
    title: 'SettlementAuthorization',
    component: SettlementAuthorization,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/message',
    title: 'Message',
    component: Message,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/newfileupload',
    title: 'NewfileUpload',
    component: Newfileupload,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/finish',
    title: 'Finish',
    component: Finish,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/fieldnames',
    title: 'Fieldnames',
    component: Fieldnames,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/csvfieldnames',
    title: 'BulkUploadFieldnames',
    component: CSVFieldNames,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/csvpreviewdata',
    title: 'BulkUploadPreviewData',
    component: CSVPreviewData,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  // Newly Added page for validating Placement Reject Rules
  {
    path: '/validateplacementrules',
    title: 'ValidatePlacementRules',
    component: ValidatePlacementRules,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/previewdata',
    title: 'PreviewData',
    component: PreviewData,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/sendnotification',
    title: 'Sendnotification',
    component: Sendnotification,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/gafaq',
    title: 'GA_FAQ',
    component: GAFAQ,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/notifications',
    title: 'Notification Center',
    component: NotificationcenterClient,
    UserRole: [UserRole.ClientAdmin, UserRole.ClientUser],
  },
  {
    path: '/globalsettings',
    title: 'Global Settings',
    component: GlobalSettings,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin]
  },

  {
    path: '/ManageClientFolderAccess',
    title: 'Manage File Access',
    component: ManageClientFolderAccess,
    UserRole: [UserRole.FileAdmin]
  },
  {
    path: '/Massupload',
    title: 'Mass Document Upload',
    component: MassUpload
  },
  {
    path: '/adfcontrol',
    title: 'ADF Environment',
    component: AdfControl,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin]
  },
  {
    path: '/ackreportlist',
    title: 'Acknowledgement Report LIst',
    component: AckReportList,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin]
  },
  {
    path: '/ackbuilder',
    title: 'ReportsBuilder',
    component: AckBuilder,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin],
    noNavLink: true
  },
  {
    path: '/webviewfiles',
    title: 'File Manager',
    component: FileManager,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin]
  },
  {
    path: '/agentuser',
    title: 'Agent User',
    component: AgentUsers,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin, UserRole.GlobalAdmin]
  },
  {
    path: '/agenttemplate',
    title: 'Agent Template',
    component: MasterTemplate,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin, UserRole.GlobalAdmin]
  },
  {
    path: '/managereports',
    title: 'Manage Reports',
    component: ManageReports,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin, UserRole.GlobalAdmin]
  },
  {
    path: '/managetemplate',
    title: 'Manage Template',
    component: ManageTemplate,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin, UserRole.GlobalAdmin]
  },
  {
    path: '/agentinquiry',
    title: 'Agent Inquiry',
    component: AgentInquiry,
    UserRole: [UserRole.AgentUser],
    AgentUserRole: [AgentUserRole.AgentUser]
  },
  {
    path: '/agentreport',
    title: 'Agent Report',
    component: AgentReport,
    UserRole: [UserRole.AgentUser],
    AgentUserRole: [AgentUserRole.AgentUser]
  },
  {
    path: '/generateagentreport',
    title: 'Agent Report',
    component: GenerateAgentReport,
    AgentUserRole: [AgentUserRole.AgentUser],
    UserRole: [UserRole.AgentUser],
    noNavLink: true
  },
  {
    path: '/agentcasecreatedreport',
    title: 'Case Created Report',
    component: AgentCaseCreatedReport,
    UserRole: [UserRole?.AgentUser],
    AgentUserRole: [AgentUserRole.AgentUser],
    noNavLink: false
  },
  {
    path: '/generateagentcasecreatedreport',
    title: 'Case Created Report',
    component: GenerateCaseCreatedReport,
    UserRole: [UserRole?.AgentUser],
    AgentUserRole: [AgentUserRole.AgentUser],
    noNavLink: true
  },
  {
    path: '/faq',
    title: 'FAQ',
    component: Faq,
    UserRole: [UserRole.AgentUser],
    AgentUserRole: [AgentUserRole.AgentUser]
  },
  {
    path: '/performancereportbuilder',
    title: 'PerformanceReportBuilder',
    component: PerformanceReportBuilder,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin],
    noNavLink: true
  },
  {
    path: '/webviewreport',
    title: 'WebviewReports',
    component: WebviewReports,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/performancereportview',
    title: 'PerformanceReportView',
    component: PerformanceReportView,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/prospectUsersList',
    title: 'ProspectUsersList',
    component: prospectUsersList,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin]
  },
  {
    path: '/globalAckReport',
    title: 'AckAdditionalReport',
    component: AckAdditionalReport,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/additionalackbuilder',
    title: 'AdditionalAckBuilder',
    component: AdditionalAckBuilder,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin],
    noNavLink: true
  },
  {
    path: '/UploadedFiles',
    title: 'FileManager',
    component: UploadedFiles,
    UserRole: [UserRole.GlobalAdmin, UserRole.SuperAdmin],
    noNavLink: true
  },
  {
    path: '/agentNotifications',
    title: 'Notification Center',
    component: AgentNotificationCenter,
    UserRole: [UserRole.AgentUser],
    AgentUserRole: [AgentUserRole.AgentUser]
  },
  {
    path: '/requestedReport',
    title: 'RequestedReport',
    component: ReportsRequested,
    noNavLink: true,
    UserRole: [UserRole.GlobalAdmin, UserRole.ClientAdmin, UserRole.ClientUser, UserRole.SuperAdmin]
  },
  {
    path: '/trustStatementReport',
    title: 'trustStatementReport',
    component: TrustStatementReport,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin]
  },
  {
    path: '/trustReportShare',
    title: 'trustReportShare',
    component: SettingsTrustReportShare,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin,UserRole.ClientAdmin]
  },
  {
    path: '/apiKeyManagement',
    title: 'apiKeyManagement',
    component: ViewKeyManagement,
    noNavLink: true,
    UserRole: [UserRole.SuperAdmin,UserRole.GlobalAdmin]
  },
]
export default Routes
