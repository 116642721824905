import { IFileRuleOperation } from "../../../../../models/accesscontrol/PlacementTemplateRule";

export const FileRuleMock: IFileRuleOperation[] = [
  {
    ruleOperationTypeId: 1,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1101,
    ruleOperationName: "Check CFPB Exempted",
    description:
      "Check whether the selected client code is exempt from the CFPB balance check. If the client code exists in the table, it is exempt",
  },
  {
    ruleOperationTypeId: 2,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1102,
    ruleOperationName: "CFPB balance not match",
    description:
      "Check if the placement amount is not equal to the sum of CFPB Balance As Of, CFPB Interest, and CFPB Fees, minus CFPB Payments/Credits. If this condition is true, reject the placement.",
  },
  {
    ruleOperationTypeId: 3,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1103,
    ruleOperationName: "Existing account in Dispute, BK or Active",
    description:
      "Check whether the placing account already exists in WebView and if its status is Active, Dispute, or BK. If it exists with any of these statuses, reject the account",
  },
  {
    ruleOperationTypeId: 4,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1104,
    ruleOperationName: "CFPB itemization date invalid",
    description:
      "Check whether the Itemization date is greater than the statute value for the State in the STF table. If it is, reject the item",
  },
  {
    ruleOperationTypeId: 5,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1105,
    ruleOperationName: "Dups-all fields matching",
    description:
      "Check whether the accounts being placed were already placed on the same date. If they were, reject the accounts",
  },
  {
    ruleOperationTypeId: 6,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1106,
    ruleOperationName: "Special name reject due to compliances",
    description:
      "Check whether the first name, last name, or full name contains any special titles such as Homeowner, Resident, or Tenant etc.. If so, reject the account",
  },
  {
    ruleOperationTypeId: 7,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1107,
    ruleOperationName: "DOB is a minor",
    description:
      "Check whether the date of birth indicates that the account holder is a minor. If so, reject the account",
  },
  {
    ruleOperationTypeId: 8,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1108,
    ruleOperationName: "Invalid State",
    description:
      "Check whether the state value for the placing account exists in the table tblUSState. If it does not exist, reject the account",
  },
  {
    ruleOperationTypeId: 9,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1109,
    ruleOperationName: "Invalid Zip Code",
    description:
      "Check whether the zip code for the placing account exists in the table tblUSPostalCodes. If it does not exist, reject the account",
  },
  {
    ruleOperationTypeId: 10,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1110,
    ruleOperationName: "Invalid or missing Last Charge Date",
    description:
      "Check whether the last charge date submitted is either not a valid date, empty, more than 100 years old, or a future date. If any of these conditions are met, reject the account",
  },
  {
    ruleOperationTypeId: 11,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1111,
    ruleOperationName: "Kinder DNC PhoneNo Removed",
    description:
      "Check whether the submitted phone number exists in the table tblDNC_List. If it does, reject the account",
  },
  {
    ruleOperationTypeId: 12,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1112,
    ruleOperationName: "Age of the Account is older than 5 years",
    description:
      "Check whether the last charge date (delinquency_date) is greater than the statute value for the State in the STF table. If it is, reject the item",
  },
  {
    ruleOperationTypeId: 13,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1113,
    ruleOperationName: "NB but referral amount is less the PIF threshold",
    description: "Reject the account if the placement amount is less than 5",
  },
  {
    ruleOperationTypeId: 14,
    ruleCategoryId: 11,
    rulecategoryName: "Business Rules",
    ruleOperationTypeEnumId: 1114,
    ruleOperationName: "Medical Accounts in NV and NM",
    description:
      "Check whether the address state value is NV or MN and if the value of CLT_Class is 02. If both conditions are met, reject the account",
  },
];

export const operators = [
  { name: 'Addition', symbol: '+' },
  { name: 'Subtraction', symbol: '-' },
  { name: 'Multiplication', symbol: '*' },
  { name: 'Division', symbol: '/' },
  { name: 'Logical AND', symbol: '&&' },
  { name: 'Logical OR', symbol: '||' },
  { name: 'Assignment', symbol: '=' },
  { name: 'Not Equal', symbol: '!=' },
  { name: 'Less Than', symbol: '<' },
  { name: 'Greater Than', symbol: '>' },
  { name: 'Less Than or Equal To', symbol: '<=' },
  { name: 'Greater Than or Equal To', symbol: '>=' }
];
